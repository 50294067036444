import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router';
const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/UserIndex',
    redirect: '/user',
	meta:{
		title: "实名制信息"
	},
    component: function () {
      return import(/* webpackChunkName: "Index" */ '../views/UserIndex.vue');
    },
    children: [
      {
        path: '/user',
        name: 'user',
        component: function () {
          return import(/* webpackChunkName: "User" */ '../views/User.vue');
        },
      }
    ],
	
  },
  
  {
    path: '/home',
    name: 'home',
	meta:{
		title: "首页"
	},
    component: function () {
      return import(/* webpackChunkName: "MonitoringList" */ '../views/home.vue');
    },
  },
  {
    // 检查单详情
    path: '/Checklist',
    name: 'Checklist',
	meta:{
		title: "检查单信息"
	},
    component: function () {
      return import(/* webpackChunkName: "MonitoringList" */ '../views/Checklist.vue');
    },
  },
  {
    // 随手拍详情
    path: '/SnapshotDetail',
    name: 'SnapshotDetail',
	meta:{
		title: "随手拍信息"
	},
    component: function () {
      return import(/* webpackChunkName: "MonitoringList" */ '../views/SnapshotDetail.vue');
    },
  },
  {
    // 移动巡检详情
    path: '/MobileInspection',
    name: 'MobileInspection',
	meta:{
		title: "移动巡检信息"
	},
    component: function () {
      return import(/* webpackChunkName: "MonitoringList" */ '../views/MobileInspection.vue');
    },
  }
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
