import { createApp, VueElement } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'amfe-flexible';
import '@/assets/scss/reset.scss';
import '@/assets/scss/common.scss';
import Vant from 'vant';
import 'vant/lib/index.css';

import { Button, List, Cell, CellGroup, Tag, Form, Field, Popup, DatetimePicker, Uploader, Tab, Tabs, Image as VanImage } from 'vant';
import { Collapse, CollapseItem } from 'vant';

import VueWechatTitle from 'vue-wechat-title';

const app = createApp(App);
app.use(Collapse);
app.use(CollapseItem);
// app.use(Button);
// app.use(List);
// app.use(Cell);
// app.use(CellGroup);
// app.use(Tag);
// app.use(Form);
// app.use(Field);
// app.use(Popup);
// app.use(DatetimePicker);
// app.use(Uploader);
// app.use(Tab);
// app.use(Tabs);
// app.use(VanImage);
app.use(Vant);
app.use(VueWechatTitle);
app.use(store).use(router).mount('#app');
router.beforeEach((to,from,next)=>{
	if(to.meta.title){
		document.title=to.meta.title
	}
	next()
});
